<template>
	<div>
		<!-- 公共头部 -->
		<Header ></Header>
		
		<div class="section1">
			<div class="item" :class="[ cIndex == 0 ? 'active' : '' ]" @click="cIndex = 0">快递领取</div>
			<div class="item" :class="[ cIndex == 1 ? 'active' : '' ]" @click="cIndex = 1">现场领取</div>
		</div>
		
		<div class="section2" v-show="cIndex == 0">
			<template v-if="formData.status == 0 || formData.status == -1">
				<div class="form-box">
					<div class="item clearfix">
						<div class="icon-box fl">
							<img class="icon" src="@/assets/images/icon55.png" />
						</div>
						<input type="text" placeholder="请输入收件人姓名" class="input fl" v-model="formData.name" />
					</div>
					<div class="item clearfix">
						<div class="icon-box fl">
							<img class="icon" src="@/assets/images/icon56.png" />
						</div>
						<input type="text" placeholder="请输入收件人手机" class="input fl" v-model="formData.tel" />
					</div>
					<div class="item clearfix">
						<div class="icon-box fl">
							<img class="icon" src="@/assets/images/icon57.png" />
						</div>
						<input type="text" placeholder="请输入收件地址" class="input fl" v-model="formData.address" />
					</div>
				</div>
				<div class="tip" v-if="formData.status == -1">驳回原因：{{formData.case}}</div>
				<div class="tip">注意事项：快递费自理！</div>
				<div class="submit">
					<div class="btn" @click="submit">提交审核</div>
				</div>
			</template>
			<template v-if="formData.status == 2">
				<div class="box3">
					<img class="icon" src="@/assets/images/icon78.png" />
					<div class="desc">教材领取成功！您的教材正在快递中，请注意查收！<br>快递公司：{{formData.exp}}<br>快递单号：{{formData.expnum}}</div>
				</div>
			</template>
			<template v-if="formData.status == 1">
				<div class="box3">
					<img class="icon" src="@/assets/images/icon79.png" />
					<div class="desc">教材领取审核中，预计三个工作日内完成，请耐心等待！</div>
				</div>
			</template>
		</div>
		
		<div class="section3" v-show="cIndex == 1">
			<div class="list">
				<div class="item">
					<div class="left-box">
						<div class="pic">
							<img class="icon" src="@/assets/images/icon52.png" />
						</div>
						<div class="title">联系人</div>
					</div>
					<div class="right-box">{{sceneInfo.name}}</div>
				</div>
				<div class="item">
					<div class="left-box">
						<div class="pic">
							<img class="icon" src="@/assets/images/icon53.png" />
						</div>
						<div class="title">咨询电话</div>
					</div>
					<div class="right-box">
						<a :href="'tel:' + sceneInfo.tel" style="color: #121e5f;text-decoration: underline;">{{sceneInfo.tel}}</a>
					</div>
				</div>
				<div class="item">
					<div class="left-box">
						<div class="pic">
							<img class="icon" src="@/assets/images/icon54.png" />
						</div>
						<div class="title">地址</div>
					</div>
					<div class="right-box">{{sceneInfo.address}}</div>
				</div>
			</div>
			<div class="tip">领取要求：请提前告知所报内容！</div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '教材领取',
				sceneInfo: '',
				formData: {
					name: '',
					tel: '',
					address: ''
				},
				cIndex: 0
			}
		},
		computed: {
			uid() {
					return this.$store.state.uid;
			}
		},
		activated(){
			this.getUserInfo();
		},
		deactivated() {
			this.sceneInfo = '';
			this.formData = {
				name: '',
				tel: '',
				address: ''
			}
			this.cIndex = 0;
		},
		methods: {
			async submit() {
				var nameReg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
				var telReg =/^1[3456789]\d{9}$/;
				var data = this.formData;
				data['uid'] = this.uid;
				data['student_id'] = this.$route.query.id;
				if(!data['uid']){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
						wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return
					}
				wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
					return false;
				} else if(!nameReg.test(data['name'])){
					this.$messageBox.alert('请输入合法的收件人姓名！', '温馨提示');
					return false;
				} else if(!telReg.test(data['tel'])){
					this.$messageBox.alert('请输入正确的11位手机号！', '温馨提示');
					return false;
				} else if(!data['address']){
					this.$messageBox.alert('请输入收件地址！', '温馨提示');
					return false;
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/study/jiaocaiReceive', data);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('教材领取 提交审核：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.$messageBox.alert('提交成功！', '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$router.go(-1);
						}
					});
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			// 获取现场和快递信息
			async getScene() {
				var student_id = this.$route.query.id;
				var uid = this.uid;
				var data = {
					uid, student_id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/user/scene', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('教材领取 现场和快递信息：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.sceneInfo = res.data;
				} else {
					this.sceneInfo = '';
				}
			},
			// 获取教材领取详情
			async getEditjiaocai() {
				var student_id = this.$route.query.id;
				var uid = this.uid;
				var data = {
					uid, student_id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/study/editjiaocai', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('教材领取 获取领取详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var formData = res.data;
					this.formData = formData;
				} else {
					this.$toast.error(res.data);
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
						wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return
					}
					wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getEditjiaocai();
					this.getScene();
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section1 .item{
		flex: 1;
		font-size: 0.28rem;
		color: #969897;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 0.8rem;
	}
	.section1 .item.active{
		font-size: 0.32rem;
		color: #ff6000;
		position: relative;
	}
	.section1 .item.active::after{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.48rem;
		height: 0.04rem;
		background: #ff6000;
		border-radius: 0.02rem;
		bottom: 0.08rem;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
	}
	
	.section2{
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		margin-top: 0.3rem;
	}
	.section2 .form-box{
		padding: 0 0.85rem;
	}
	.section2 .form-box .item{
		border: 0.02rem solid #b1b1b1;
		border-radius: 0.5rem;
		margin-bottom: 0.3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.1rem 0;
		position: relative;
	}
	.section2 .form-box .item::before{
		content: '*';
		display: inline-block;
		position: absolute;
		font-size: 0.3rem;
		color: #ff6000;
		right: -0.4rem;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
	}
	.section2 .form-box .item .icon-box{
		border-radius: 0.5rem;
		width: 0.34rem;
		height: 0.34rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.section2 .form-box .item .icon-box .icon{
		max-height: 100%;
	}
	.section2 .form-box .item .input{
		font-size: 0.24rem;
		color: #969897;
		height: 0.6rem;
		border: none;
		width: calc(100% - 1rem);
		outline: none;
		margin-left: 0.12rem;
	}
	.section2 .tip{
		margin: 0.6rem 0.85rem 0;
		font-size: 0.28rem;
		color: #ff6000;
		padding-left: 0.5rem;
		line-height: 0.4rem;
		background: url(../../../assets/images/icon22.png) no-repeat left center;
		background-size: 0.34rem 0.35rem;
	}
	.section2 .submit{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.6rem;
	}
	.section2 .submit .btn{
		width: 5.8rem;
		height: 0.8rem;
		background: #121e5f;
		border-radius: 0.5rem;
		cursor: pointer;
		font-size: 0.32rem;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.section2 .box3{
		text-align: center;
		padding-top: 0.4rem;
	}
	.section2 .box3 .icon{
		width: 1.08rem;
	}
	.section2 .box3 .desc{
		line-height: 0.5rem;
		margin-top: 0.3rem;
	}
		
	.section3{
		margin-top: 0.3rem;
	}
	.section3 .list{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section3 .item{
		overflow: hidden;
		padding: 0.3rem;
		border-top: 0.02rem solid #e7e7e7;
	}
	.section3 .item:first-child{
		border-top: none;
	}
	.section3 .item .left-box{
		display: flex;
		align-items: center;
		float: left;
		line-height: 0.4rem;
	}
	.section3 .item .left-box .pic{
		width: 0.34rem;
		height: 0.34rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.section3 .item .left-box .pic .icon{
		
	}
	.section3 .item .left-box .title{
		font-size: 0.28rem;
		color: #333333;
		margin-left: 0.2rem;
	}
	.section3 .item .right-box{
		float: right;
		font-size: 0.26rem;
		color: #969897;
		max-width: 4.2rem;
		line-height: 0.4rem;
	}
	.section3 .tip{
		margin: 0.5rem 0.3rem 0;
		padding-left: 0.55rem;
		font-size: 0.28rem;
		color: #ff6000;
		background: url(../../../assets/images/icon22.png) no-repeat left center;
		background-size: 0.34rem 0.35rem;
		line-height: 0.4rem;
	}
</style>